import React from "react";
import { Helmet } from "react-helmet";
import "./Gallery.css";
import Navigation from "../../components/Navigation";
import GalleryCarousel from "../../components/GalleryCarousel.js";
import { events, imageIds } from "../../data/imageData";
import ImageGrid from "../../components/ImageGrid.js";

export default function Gallery() {
  return (
    <div
      className="Gallery"
      id="gallery-container"
    >
      <Helmet>
        <title>
          Gallery - Explore our Stunning Event Venue Rental Space at Sixth &
          Main Event Center
        </title>
        <meta
          name="description"
          content="Browse through our gallery to see the beauty of events, from baby showers to company parties, hosted at Sixth & Main Event Center. Get inspired for your next occasion."
        />
        <link
          rel="icon"
          href="/favicon.ico"
          sizes="any"
        />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link
          rel="manifest"
          href="/site.webmanifest"
        />
        <link
          rel="mask-icon"
          href="/safari-pinned-tab.svg"
          color="#000000"
        />
        <meta
          name="msapplication-TileColor"
          content="#000000"
        ></meta>
      </Helmet>
      <div className="hero">
        <Navigation />
        <div>
          <h1 className="text-white px-3 px-sm-5 mx-sm-5">
            Explore our stunning venue rental space
          </h1>
        </div>
      </div>
      <div
        name="gallery"
        className="Gallery container mb-5"
        id="content-wrap"
      >
        <h3
          className="mt-sm-5 mb-3 py-4 text-center text-dark fs-1 fw-medium"
          id="heading"
        >
          Venue Interior
        </h3>
        <GalleryCarousel />
      </div>
      <div
        className="gallery-summary d-block m-auto px-md-5 px-2 py-5 text-center"
        id="gallery-summary-container"
      >
        <p className="mb-3 fs-5 fw-light">
          The main area features natural light, high ceilings, and neutral
          linens providing a blank canvas for you to personalize with your own
          decorations and style.
        </p>
        <iframe
          className="rounded img-fluid d-block m-auto"
          src="https://www.youtube.com/embed/6YAl0zQG7Mc?si=ocSwiPdD1ZfXHruO"
          title="YouTube video player"
          alt="Video tour inside Robstown, Texas venue, Sixth & Main Event Center"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen="true"
        ></iframe>

        <p className="mt-3 fs-5 fw-light">
          The venue layout allows for flexible seating arrangements.
        </p>
      </div>

      <div className="container past-events">
        <h2 className="text-center mt-4 text-dark">Past Events</h2>
        <h3 className="text-center mb-4 text-dark">
          Sixth & Main Event Center
        </h3>
        {imageIds.map((group, index) => (
          <ImageGrid
            key={index}
            imageIds={group}
            event={events[index]}
          />
        ))}
      </div>

      {/* <section
        className="Gallery container mw-100 my-5"
        id="past-events"
      >
        <EventsGrid />
      </section> */}

      <div className="row mt-5 pb-5 w-75 m-auto">
        <div className="col-12 col-lg-6 mb-2">
          <a
            href="/tour"
            className="btn btn-primary text-uppercase"
            role="button"
          >
            Schedule Tour
          </a>
        </div>
        <div className="col-12 col-lg-6">
          <a
            href="/reservation"
            className="btn btn-primary text-uppercase"
            role="button"
          >
            Schedule Reservation
          </a>
        </div>
      </div>
    </div>
  );
}
